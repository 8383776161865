    <template>
    <div>    
        <b-row v-if="projects" class="justify-content-center match-height mt-1 ml-1 mr-1 mb-1" >
            <b-col xl="4" lg="4" md="6" sm="12" v-for="item in projects" :key="item.projectID">
                <b-row>
                    <!-- Only cel -->
                    <b-col class="d-md-none d-lg-none d-xl-none"  v-if="parseFloat(item.investmentCollected) >= parseFloat(item.investmentRequired)" style="justify-content:center;">                        
                        <div style="width:70%;
                                    position:absolute;
                                    z-index:1;
                                    "
                                        >                            
                            <h1 style="color:white; 
                                        font-size: 40px;                                        
                                        border-radius: 3px;                                                   
                                        text-align:center;                                        
                                        background-color: #FF9F43;
                                        ">VENDIDO
                            </h1>
                        </div> 
                    </b-col>
                    <b-col class="d-md-none d-lg-none d-xl-none"  v-else-if="item.isBlockedForInvestment" style="justify-content:center;">                        
                        <div style="width:70%;
                                    position:absolute;
                                    z-index:1;
                                    "
                                        >                            
                            <h1 style="color:white; 
                                        font-size: 40px;                                        
                                        border-radius: 3px;                                                   
                                        text-align:center;                                        
                                        background-color: #FF9F43;
                                        ">CERRADO
                            </h1>
                        </div> 
                    </b-col>

                    <!-- No cel -->
                    <b-col class="d-none d-md-block" v-if="parseFloat(item.investmentCollected) >= parseFloat(item.investmentRequired)" style="justify-content:center;">                        
                        <div style="width:50%;
                                    position:absolute;
                                    z-index:1;
                                    "
                                        >                            
                            <h1 style="color:white; 
                                        font-size: 40px;                                        
                                        border-radius: 3px;                                                   
                                        text-align:center;                                        
                                        background-color: #FF9F43;
                                        ">VENDIDO
                            </h1>
                        </div> 
                    </b-col>
                    <b-col class="d-none d-md-block" v-else-if="item.isBlockedForInvestment" style="justify-content:center;">                        
                        <div style="width:50%;
                                    position:absolute;
                                    z-index:1;
                                    "
                                        >                            
                            <h1 style="color:white; 
                                        font-size: 40px;                                        
                                        border-radius: 3px;                                                   
                                        text-align:center;                                        
                                        background-color: #FF9F43;
                                        ">CERRADO
                            </h1>
                        </div> 
                    </b-col>
                </b-row>

                <b-card v-if="parseFloat(item.investmentCollected) >= parseFloat(item.investmentRequired) || item.isBlockedForInvestment"                            
                    style="opacity:0.3"                    
                    img-alt="Card image cap"
                    img-height="300px"                    
                    img-top           
                    object-fit= cover         
                    >

                <b-carousel id="carousel-example-generic" controls indicators :interval="0" img-height="700px">          
                    <b-carousel-slide v-if="item.projectVideoUrl">
                        <template #img>
                        <iframe :src="item.projectVideoUrl" class="sosty-project-details-video" frameborder="0" style="max-height:300px" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                  
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide v-if="item.projectImageUrl1">
                        <template #img>
                        <b-img :src="item.projectImageUrl1" class="sosty-project-details-image" fluid alt="Responsive image" style="max-height:300px" />
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide v-if="item.projectImageUrl2">
                        <template #img>
                        <b-img :src="item.projectImageUrl2" class="sosty-project-details-image" fluid alt="Responsive image" style="max-height:300px" />
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide v-if="item.projectImageUrl3">
                        <template #img>
                        <b-img :src="item.projectImageUrl3" class="sosty-project-details-image" fluid alt="Responsive image" style="max-height:300px" />
                        </template>
                    </b-carousel-slide>
                </b-carousel> 
                <b-card-title>
                    <b-row>
                        <b-col >
                            <h3>{{item.projectName}} ({{item.projectCode}})</h3>
                        </b-col>
                        <b-col> 
                            <b-row style="align-items:center;">  
                                <b-col style="text-align:right;">
                                    <h6 style="font-size:10px; color:#00bd56;">Póliza de seguro
                                        <b-avatar size="18" variant="light-primary">
                                            <feather-icon size="14" icon="ShieldIcon"/>
                                        </b-avatar>  
                                    </h6>                              
                                </b-col>
                            </b-row>

                            <b-row style="align-items:center;">
                                <b-col style="text-align:right">                                     
                                    <h6 style="font-size:10px; color:#00bd56;">Monitoreo Sosty
                                        <b-avatar  size="18" variant="light-primary">
                                            <feather-icon size="14" icon="FileIcon"/>
                                        </b-avatar>   
                                    </h6>                    
                                </b-col>         
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card-title>                                        
                <b-card-body class="statistics-body">
                    <b-row>
                        <b-col md="6" lg="6" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="BarChartIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{(item.projectProfitability)}} % (E.A)</h4>
                                    <b-card-text class="font-small-3 mb-0">Rentabilidad Estimada*</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="6" lg="6" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">                        
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="UsersIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{ item.amountOfInvestors }}</h4>
                                    <b-card-text class="font-small-3 mb-0">NeoGanaderos</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="6" lg="6" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="DollarSignIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">${{parseFloat(minimalInvestment).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} <span style="font-size:12px">COP</span></h4>
                                    <b-card-text class="font-small-3 mb-0">Inversión mínima</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="6" lg="6" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                             <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="CalendarIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body v-if="item.daysLeft >= 4" class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{ item.daysLeft }} dias</h4>
                                    <b-card-text class="font-small-3 mb-0">Restantes</b-card-text>
                                </b-media-body>
                                <b-media-body v-else class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{ parseFloat(item.daysLeft)*24 }} horas</h4>
                                    <b-card-text class="font-small-3 mb-0">Restantes</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                    </b-row>
                </b-card-body>

                <b-row>
                    <b-col md="12" lg="12">
                        <b-row class="mt-1" v-if="item.projectType == 'Levante' || item.projectType == 'Ceba'">
                            <b-col md="6" lg="6">
                                <b-card-text v-if="item.investmentRequired!=0" class="mb-50"> 
                                    {{(parseFloat(item.investmentCollected) * parseFloat(item.amountOfCattles) / parseFloat(item.investmentRequired)).toFixed(2)}} 
                                    Animales ({{parseFloat(item.investmentCollected).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Kg)</b-card-text>                                
                                <b-card-text v-else class="mb-50">0 Animales ({{ item.investmentCollected }}) Kg</b-card-text>
                            </b-col>
                            <b-col md="6" lg="6" class="text-right">
                                <b-card-text class="mb-50">{{item.amountOfCattles}} Animales ({{parseFloat(item.investmentRequired).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Kg)</b-card-text>
                            </b-col>
                        </b-row>   
                        
                        <b-row class="mt-1" v-if="item.projectType == 'Cria'">
                            <b-col md="6" lg="6">
                                <b-card-text v-if="item.investmentRequired!=0" class="mb-50"> 
                                    {{parseFloat(item.investmentCollected).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Sostycs</b-card-text>                                
                                <b-card-text v-else class="mb-50">0 Sostycs</b-card-text>
                            </b-col>
                            <b-col md="6" lg="6" class="text-right">
                                <b-card-text class="mb-50">{{parseFloat(item.investmentRequired).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Sostycs</b-card-text>
                            </b-col>
                        </b-row>  

                            <b-progress animated v-if="item.minBar>5" striped :max="max" height="10px">
                                <b-progress-bar animated variant="primary" :value="item.projectProgres"></b-progress-bar>                  
                                <b-progress-bar animated variant="warning" :value="item.minBar" >min</b-progress-bar>                  
                            </b-progress>
                            <b-progress animated v-else-if="item.minBar > 0 && item.minBar <= 5" striped :max="max" height="10px">
                                <b-progress-bar animated variant="primary" :value="item.projectProgres"></b-progress-bar>                  
                                <b-progress-bar animated variant="warning" :value="item.minBar" ></b-progress-bar>                  
                            </b-progress>
                            <b-progress animated v-else striped :max="max" height="10px">
                                <b-progress-bar animated variant="primary" :value="item.projectProgres"></b-progress-bar>
                            </b-progress>  
                        
                        <b-card-text v-if="item.investmentRequired!=0" class="mb-50">{{ ((parseFloat(item.investmentCollected)/parseFloat(item.investmentRequired))*100).toFixed(2) }} % Recaudado</b-card-text>  
                    </b-col>
                </b-row>  
                <b-row v-if="item.projectProgres < item.minRequiredToStart">      
                    <b-col md="12" lg="12">
                        <b-alert variant="info" class="mb-0" show>
                            <div style="cursor:pointer;" b-tooltip.hover title="En caso de no completar el mínimo, te devolvemos el dinero" class="alert-body font-small-1">
                            <span style="font-size:14px"><strong>Ayúdanos a completar el mínimo para comenzar el proyecto</strong></span>
                            </div>
                        </b-alert>
                    </b-col>
                    </b-row>

                    <b-row v-else-if="item.projectProgres < item.minRequiredToStart || item.projectProgres < 100">
                    <b-col>
                        <b-alert variant="warning" show>
                            <div class="alert-body font-small-1">
                                <span style="font-size:14px">
                                    <strong>
                                        En caso de no completar el 100% se comprarán los {{Math.round(item.currentAmountOfCattles)}} animales actuales y la rentabilidad puede variar un poco
                                    </strong>
                                </span>
                            </div>
                        </b-alert>
                    </b-col>
                </b-row> 

                <b-row class="mt-2">
                    <b-col md="6" lg="6" v-if="parseFloat(item.investmentCollected) >= parseFloat(item.investmentRequired)">
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="danger" @click="showModalRisks" block disabled>¿Que Riesgos tiene?</b-button>
                    </b-col>
                    <b-col md="6" lg="6" v-else-if="item.isBlockedForInvestment">
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="danger" @click="showModalRisks" block disabled>¿Que Riesgos tiene?</b-button>
                    </b-col>                    
                    <b-col md="6" lg="6" v-else>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="danger" @click="showModalRisks" block>¿Que Riesgos tiene?</b-button>
                    </b-col>

                    <b-col md="6" lg="6" v-if="parseFloat(item.investmentCollected) >= parseFloat(item.investmentRequired)">
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="goToInvest(item.projectCode)" block disabled>Invertir</b-button>
                    </b-col>
                    <b-col md="6" lg="6" v-else-if="item.isBlockedForInvestment">
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="goToInvest(item.projectCode)" block disabled>Invertir</b-button>
                    </b-col>
                    <b-col md="6" lg="6" v-else>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="goToInvest(item.projectCode)" block>Invertir</b-button>
                    </b-col>
                </b-row>
                </b-card> 

                <b-card v-else
                    img-alt="Card image cap"
                    img-height="300px"                                    
                    img-top 
                    object-fit= cover               
                    >
                    <b-carousel id="carousel-example-generic" controls indicators :interval="0" img-height="700px">          
                        <b-carousel-slide v-if="item.projectVideoUrl">
                            <template #img>
                            <iframe :src="item.projectVideoUrl" class="sosty-project-details-video" frameborder="0" style="max-height:300px" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                  
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide v-if="item.projectImageUrl1">
                            <template #img>
                            <b-img :src="item.projectImageUrl1" class="sosty-project-details-image" fluid alt="Responsive image" style="max-height:300px" />
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide v-if="item.projectImageUrl2">
                            <template #img>
                            <b-img :src="item.projectImageUrl2" class="sosty-project-details-image" fluid alt="Responsive image" style="max-height:300px" />
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide v-if="item.projectImageUrl3">
                            <template #img>
                            <b-img :src="item.projectImageUrl3" class="sosty-project-details-image" fluid alt="Responsive image" style="max-height:300px" />
                            </template>
                        </b-carousel-slide>
                    </b-carousel> 
                <b-card-title>
                    <b-row class="mb-0">
                        <b-col>
                            <h3>{{item.projectName}} ({{item.projectCode}})</h3>
                        </b-col>
                        <b-col> 
                            <b-row style="align-items:center;">  
                                <b-col style="text-align:right;">
                                    <h6 style="font-size:10px; color:#00bd56;">Póliza de seguro
                                        <b-avatar style="cursor:pointer;" size="18" variant="light-primary">
                                            <feather-icon size="14" icon="ShieldIcon"/>
                                        </b-avatar>  
                                    </h6>                              
                                </b-col>
                            </b-row>

                            <b-row style="align-items:center;">
                                <b-col style="text-align:right">                                     
                                    <h6 style="font-size:10px; color:#00bd56;">Monitoreo Sosty
                                        <b-avatar  size="18" variant="light-primary">
                                            <feather-icon size="14" icon="FileIcon"/>
                                        </b-avatar>   
                                    </h6>                    
                                </b-col>         
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card-title>                                       
                <b-card-body class="statistics-body">
                    <b-row>
                        <b-col md="6" lg="6" class="mb-1 mb-md-0 mb-2 mb-xl-0">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="BarChartIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{(item.projectProfitability)}} % (E.A)</h4>
                                    <b-card-text class="font-small-3 mb-0">Rentabilidad Estimada*</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="6" lg="6" class="mb-1 mb-md-0 mb-2 mb-xl-0">
                            <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="UsersIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{ item.amountOfInvestors }}</h4>
                                    <b-card-text class="font-small-3 mb-0">NeoGanaderos</b-card-text>
                                </b-media-body>
                            </b-media>

                        </b-col>
                        <b-col md="6" lg="6" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                            <b-media no-body>
                            <b-media-aside class="mr-2">
                                <b-avatar size="48" variant="light-primary">
                                <feather-icon size="24" icon="DollarSignIcon"/>
                                </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                                <h4 class="font-weight-bolder mb-0">${{parseFloat(minimalInvestment).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} <span style="font-size:12px">COP</span></h4>
                                <b-card-text class="font-small-3 mb-0">Inversión mínima</b-card-text>
                            </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="6" lg="6" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                             <b-media no-body>
                                <b-media-aside class="mr-2">
                                    <b-avatar size="48" variant="light-primary">
                                    <feather-icon size="24" icon="CalendarIcon"/>
                                    </b-avatar>
                                </b-media-aside>
                                <b-media-body v-if="item.daysLeft >= 4" class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{ item.daysLeft }} dias</h4>
                                    <b-card-text class="font-small-3 mb-0">Restantes</b-card-text>
                                </b-media-body>
                                <b-media-body v-else class="my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{ parseFloat(item.daysLeft)*24 }} horas</h4>
                                    <b-card-text class="font-small-3 mb-0">Restantes</b-card-text>
                                </b-media-body>
                            </b-media>
                        </b-col>
                    </b-row>
                </b-card-body>

                <b-row>
                    <b-col md="12" lg="12">
                        <b-row class="mt-1" v-if="item.projectType == 'Levante' || item.projectType == 'Ceba'">
                            <b-col md="6" lg="6">
                                <b-card-text v-if="item.investmentRequired!=0" class="mb-50"> 
                                    {{(parseFloat(item.investmentCollected) * parseFloat(item.amountOfCattles) / parseFloat(item.investmentRequired)).toFixed(2)}} 
                                    Animales ({{parseFloat(item.investmentCollected).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Kg)</b-card-text>                                
                                <b-card-text v-else class="mb-50">0 Animales ({{ item.investmentCollected }}) Kg</b-card-text>
                            </b-col>
                            <b-col md="6" lg="6" class="text-right">
                                <b-card-text class="mb-50">{{item.amountOfCattles}} Animales ({{parseFloat(item.investmentRequired).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Kg)</b-card-text>
                            </b-col>
                        </b-row>   
                        
                        <b-row class="mt-1" v-if="item.projectType == 'Cria'">
                            <b-col md="6" lg="6">
                                <b-card-text v-if="item.investmentRequired!=0" class="mb-50"> 
                                    {{parseFloat(item.investmentCollected).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Sostycs</b-card-text>                                
                                <b-card-text v-else class="mb-50">0 Sostycs</b-card-text>
                            </b-col>
                            <b-col md="6" lg="6" class="text-right">
                                <b-card-text class="mb-50">{{parseFloat(item.investmentRequired).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Sostycs</b-card-text>
                            </b-col>
                        </b-row>                      

                        <b-progress animated v-if="item.minBar>5" striped :max="max" height="10px">
                            <b-progress-bar animated variant="primary" :value="item.projectProgres"></b-progress-bar>                  
                            <b-progress-bar animated variant="warning" :value="item.minBar" >min</b-progress-bar>                  
                        </b-progress>
                        <b-progress animated v-else-if="item.minBar > 0 && item.minBar <= 5" striped :max="max" height="10px">
                            <b-progress-bar animated variant="primary" :value="item.projectProgres"></b-progress-bar>                  
                            <b-progress-bar animated variant="warning" :value="item.minBar" ></b-progress-bar>                  
                        </b-progress>
                        <b-progress animated v-else striped :max="max" height="10px">
                            <b-progress-bar animated variant="primary" :value="item.projectProgres"></b-progress-bar>
                        </b-progress>  

                        <b-card-text v-if="item.investmentRequired!=0" class="mb-50">{{ (parseFloat(item.investmentCollected)/parseFloat(item.investmentRequired)*100).toFixed(2) }} % Recaudado</b-card-text>  
                    </b-col>
                </b-row>

                <b-row v-if="item.projectProgres < item.minRequiredToStart">      
                    <b-col md="12" lg="12">
                        <b-alert variant="info" class="mb-0" show>
                            <div style="cursor:pointer;" b-tooltip.hover title="En caso de no completar el mínimo, te devolvemos el dinero" class="alert-body font-small-1">
                            <span style="font-size:14px"><strong>Ayúdanos a completar el mínimo para comenzar el proyecto</strong></span>
                            </div>
                        </b-alert>
                    </b-col>
                    </b-row>
                    <b-row v-else-if="item.projectProgres < item.minRequiredToStart || item.projectProgres < 100">
                    <b-col>
                        <b-alert variant="warning" show>
                            <div class="alert-body font-small-1">
                                <span style="font-size:14px">
                                    <strong>
                                        En caso de no completar el 100% se comprarán los {{Math.round(item.currentAmountOfCattles)}} animales actuales y la rentabilidad puede variar un poco
                                    </strong>
                                </span>
                            </div>
                        </b-alert>
                    </b-col>
                </b-row> 

                <b-row class="mt-2">
                    <b-col md="6" lg="6">
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="danger" @click="showModalRisks" block>¿Que Riesgos tiene?</b-button>
                    </b-col>
                    <b-col md="6" lg="6" v-if="parseFloat(item.investmentCollected) >= parseFloat(item.investmentRequired)">
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="goToInvest(item.projectCode)" block disabled>Invertir</b-button>
                    </b-col>
                    <b-col md="6" lg="6" v-else-if="item.isBlockedForInvestment">
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="goToInvest(item.projectCode)" block disabled>Invertir</b-button>
                    </b-col>
                    <b-col md="6" lg="6" v-else>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="goToInvest(item.projectCode)" block>Invertir</b-button>
                    </b-col>
                </b-row>
            </b-card>

            </b-col>        
        </b-row> 

        <b-row v-if="projects.length <= 0" >
            <b-col xl="4" md="6" lg="4" offset-lg="4" offset-md="3" offset-xl="4"  >
                <b-alert variant="primary" show>
                    <div class="alert-body">
                        <span><strong>No Hay Proyectos Disponibles</strong></span>
                    </div>
                </b-alert>
            </b-col>
        </b-row>

        <b-modal ref="modal-risk" hide-footer centered title="Riesgos">
          <risks-modal-component></risks-modal-component>
          <b-row class="mb-1 mt-2">
            <b-col md="12" lg="12">
                <b-button size="lg" variant="primary" block @click="hideRisksModal">Aceptar</b-button>
            </b-col>
          </b-row>
        </b-modal>
    </div>
</template>

<script>
import {
  BAlert, BCard, BCardText, BForm, BFormText, BInputGroupAppend, BFormInput, BInputGroupText, BFormTextArea, BInputGroupPrepend, 
  BInputGroup, BFormGroup, BFormSelect, BButton, BRow, BCol, BImg, BListGroup, BListGroupItem, BCardBody, BCardTitle, BCardSubTitle, 
  BLink, BProgress, BProgressBar, BMedia, BAvatar, BMediaAside, BMediaBody,BTooltip, BCarousel, BCarouselSlide
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import axios from '@axios'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import RisksModalComponent from '@core/components/RisksModalComponent'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BAlert,
    BCard,
    BCardText,
    BFormGroup,
    BForm,
    BFormSelect,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCarousel, 
    BCarouselSlide,
    BRow,
    BCol,
    BFormTextArea,
    BFormText,
    BFormInput,
    BLink,
    BListGroup,
    BListGroupItem,    
    BImg,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroupText,
    BProgress,
    BProgressBar,
    StatisticCardHorizontal,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCardCode,
    BTooltip,
    RisksModalComponent,

    },
    data(){
        return{         
            projectID: null,                             
            projects:[],
            currentAmountOfCattles: null,
            pesoFinal: null,
            minimalInvestment:700000,
            isLoggedIn: false,
            userType:false,
            max: 100,
        }  
    },
    created(){
        var userData = getUserData();
            if(userData){
            this.isLoggedIn = true;
            this.userType = userData.userType;
            }
        this.GetPublicTopProjects();          
    },
    methods:{
        GetPublicTopProjects(){
            axios.get('/Project/GetPublicTopProjects')
            .then(response => {                
                this.projects = response.data;
                if(this.projects.length > 0){
                    this.projects.forEach(function(item, index){

                    if(item.investmentRequired != 0){
                        var b = (parseFloat(item.investmentCollected) * parseFloat(item.amountOfCattles) / parseFloat(item.investmentRequired)).toFixed(2);
                        item.currentAmountOfCattles = parseFloat(b)
                    }else{
                        item.currentAmountOfCattles = 0;
                    }
                    
                    item.minBar= 0;
                    item.minRequiredToStart= 0;
                    item.minKgsRequested= 0;
                    item.minCattlesRequired = 25;
                    
                    item.projectProgres = parseFloat(item.projectProgres)
                    item.minKgsRequested = parseFloat(item.investmentRequired)*parseFloat(item.minCattlesRequired)/100          
                    item.minRequiredToStart = (parseFloat(parseFloat(item.minKgsRequested))*100/Math.round(parseFloat(item.investmentRequired)).toFixed(2))
                    item.minBar = parseFloat(item.minRequiredToStart).toFixed(2) - parseFloat((item.projectProgres));
                    });
                }
            })
        },
        goToInvest(projectCode) {        
            if(!this.isLoggedIn){                
                let route = this.$router.resolve({path: 'login?redirectToProjectCode=' + projectCode});
                window.open(route.href, '_blank');
            }else{
                let route = this.$router.resolve({path: 'public-project-details?projectCode=' + projectCode});
                window.open(route.href, '_blank');                
            }   
        },
        showModalRisks(){
            this.$refs['modal-risk'].show()
        },
        hideRisksModal(){
            this.$refs['modal-risk'].hide()
        }
    },
    directives: {
        Ripple,
    },     
}
</script>